"use client";

import React, { useEffect, useRef } from "react";
import { useSearchParams, usePathname } from "next/navigation";

const Affiliate = () => {
    const searchParams = useSearchParams();
    const pathname = usePathname();

    const refId = searchParams.get("ref");

    const wasCalled = useRef(false);

    useEffect(() => {
        if (wasCalled.current) return;
        wasCalled.current = true;

        const setAffiliateURL = async (refId, url) => {
            const res = await fetch("/api/affiliate/visits", {
                method: "POST",
                body: JSON.stringify({ ref: refId, url }),
            });

            // const data = await res.json();
        };

        if (refId) {
            setAffiliateURL(refId, `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}${pathname}`);
        }
    }, []);

    return null;
};

export default Affiliate;
